import {CameraOutlined} from '@ant-design/icons'
import axios from "axios";
import React, { useEffect, useState } from "react";
import { BaseUrl } from "../adminPage/BaseUrl";

const Personal = ({ PersonalData, setPersonalData,SelectValue,setSelectValue }) => {

    const [viloyat, setViloyt] = useState([])
    const [tuman, setTuman] = useState([])
    const [kod, setKod] = useState("")
    const [section, setSection] = useState([])

    // console.log(SelectValue);

    const getViloyat = () => {
        axios.get(BaseUrl + "/api/viloyatlar", {
            headers: {
                "Authorization": "Bearer Bearer " + localStorage.getItem("token")
            }
        }).then(res => {
            setViloyt(res.data)
            // console.log(res.data);
        }).catch(err => {
            console.log(err)
        })
    }

    // useEffect(()=>{
        // if(PersonalData.jshshr.trim().length >= 14){
        //     alert("Jshshir raqam soni 14 tadan oshib ketmasligi kerak")
        // }
    // },[])
    // console.log(PersonalData.jshshr.length);

    const handleChangeJshshr = (e) =>{
        if(PersonalData.jshshr.length > 14){
            setPersonalData({...PersonalData, jshshr:e.target.value})
        }
        else{alert("Hato");}
        console.log(e.target.value);
    }

    console.log('shu', PersonalData.jshshr.length);

    const postViloyatKod = (item) => {
        if (PersonalData.region != "") {
            axios.post(BaseUrl + "/api/tumanlar/" + PersonalData.region, {}, {
                headers: {
                    "Authorization": "Bearer Bearer " + localStorage.getItem("token")
                }
            }).then(res => {
                console.log(PersonalData.region)
                setTuman(res.data)
                // console.log(key.key);
            }).catch(err => {
                console.log(err)
                // console.log(localStorage.getItem("token"));
            })
        }
    }

    const getSection = () =>{
        axios.get(BaseUrl+"/api/superAdmin/section",{
            headers: {
                "Authorization": "Bearer Bearer " + localStorage.getItem("token")
            }
        }).then(res=>{
            console.log(res.data);
            setSection(res.data.sections)
        }).catch(err=>{
            console.log(err);
        })
    }

    useEffect(() => {
        postViloyatKod()
    }, [PersonalData.region])

    useEffect(() => {
        getViloyat()
        getSection()
    }, [])
    // console.log(kod);
 
    // const ckeckJshshr = ()=>{
        
    // }

    const [image, setImage] = useState("")

    function baseImage (event) {
        let files = event.target.files;
        let reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload = (e) => {
            setImage(e.target.result);
        }
        setPersonalData({
            ...PersonalData,
            foto3x4: event.target.files[0],
        });
        console.log(PersonalData);
    }

   

    // const baseImage2 = (e) =>{
       
    // }


    // console.log(image);

    return (
        <div className="card" style={{ border: "none" }}>
            {/* {console.log(props)} */}
            <div className="card-body" style={{ border: "none" }}>
                {/* {JSON.stringify(Personal)} */}
                <h4>Shaxsiy ma'lumotlar</h4>
                <hr />
                <div style={{width:"100%"}}>
                {localStorage.getItem("userRole") == 0 ? <div>
                            <div style={{display:"flex",justifyContent:"end"}}>
                            <select  style={{boxShadow:"none"}} value={PersonalData.section_id} onChange={(e) => { setPersonalData({ ...PersonalData, section_id: +e.target.value }) }} className="form-select mb-4">
                            <option selected>Bo'limlarni tanlang</option>
                                {
                                    section && section.map((item, index)=>(
                                        <option value={item.id} key={index}>{item.name}</option>
                                    ))
                                }
                            </select>
                            </div>
                        </div> :""}
                </div>
                <div style={{width:"100%", height:"180px",display:"flex",justifyContent:"center",marginBottom:"20px"}}>
                    <div style={{width:"40%",height:"100%",display:"flex",justifyContent:"center"}}>
                        <input onChange={baseImage} style={{position:"absolute",width:"200px",height:"180px",opacity:"0"}} type="file"/>
                        <div className="border" style={{width:"200px",height:"180px",borderRadius:"10px"}}>
                            {
                                image.length ? <img style={{width:"200px",height:"180px",borderRadius:"10px"}} src={image}/> :
                                <CameraOutlined style={{fontSize:"60px",margin:"60px 70px"}}/>
                            }
                        </div>
                        <p style={{position:"absolute",marginTop:"180px"}}>Rasim yuklash</p>
                    </div>
                </div>
                <div style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
                    <div style={{ width: "40%" }}>
                        <label>* Jinsingizni tanlang:</label>
                        <div>
                            <select value={Personal.gander} className="form-select" onChange={(e) => { setPersonalData({ ...PersonalData, gander: e.target.value })}}>
                        <option selected disabled>Jinsingizni tanlang</option>
                                    <option value={"Erkak"}>Erkak</option>
                                    <option value={"Ayol"}>Ayol</option>
                            </select>
                            </div>
                    </div>
                    <div style={{ width: "40%" }}>
                        <label>* Tugilgan sana:</label>
                        <div><input value={Personal.birthDate} className="form-select" onChange={(e) => { setPersonalData({ ...PersonalData, birthDate: e.target.value })}} style={{ boxShadow: "none" }} className="form-control" type="date" /></div>
                    </div>
                </div>
                <div style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
                    <div style={{ width: "40%" }}>
                        <label className=" mt-3" >* J.SH.SH.I.R:</label>
                        <div><input value={PersonalData.jshshr} onChange={(e)=>setPersonalData({...PersonalData, jshshr:e.target.value})} maxLength="14"  className="form-control" type="text" /></div>
                    </div>
                    <div style={{ width: "40%" }}>
                        <label className=" mt-3">* Pasport berilgan sana:</label>
                        <div><input value={PersonalData.passport} onChange={(e) => { setPersonalData({ ...PersonalData, passport: e.target.value }) }} style={{ boxShadow: "none" }} className="form-control" type="date" /></div>
                    </div>
                </div>
                <div>
                    <label className="mt-4">* Ism Familiya Sharif: </label>
                    <div><input value={PersonalData.fullName} onChange={(e) => { setPersonalData({ ...PersonalData, fullName: e.target.value }) }} style={{ boxShadow: "none" }} className="form-control" type="text" /></div>
                </div>
                <div className="mt-4" style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
                    <div style={{ width: "40%" }}>
                        <label>* Tug'ilgan viloyati:</label>
                        <div>
                            <select value={PersonalData.region} className="form-select" onChange={(e) => { setPersonalData({ ...PersonalData, region: e.target.value }); viloyat.map(item => (setKod(item.kod))) }}>
                            <option selected >Viloyatni tanlang</option>
                                {viloyat && viloyat.map((item, index) => (
                                    <option  value={item.kod}key={index}>{item.lotin}</option>
                                ))}
                            </select>
                        </div>
                    </div>

                    <div style={{ width: "40%" }}>
                        <label>* Tug'ilgan tumani:</label>
                        <div>
                        <select value={Personal.distirct} className="form-select" onChange={(e) => { setPersonalData({ ...PersonalData, distirct: e.target.value })}}>
                        <option selected disabled>Tumanni tanlang</option>
                                {tuman && tuman.map((item, index) => (
                                    <option key={index} value={item.lotin}>{item.lotin}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
                <div className="mt-3" style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
                    <div style={{ width: "40%" }}>
                        <label>* MFY :</label>
                        <div><input value={PersonalData.MFY} onChange={(e) => { setPersonalData({ ...PersonalData, MFY: e.target.value }) }} style={{ boxShadow: "none" }} style={{ boxShadow: "none" }} className="form-control" type="text" /></div>
                    </div>
                    <div style={{ width: "40%" }}>
                        <label>* Ko'cha va uy raqami:</label>
                        <div><input value={PersonalData.street} onChange={(e) => { setPersonalData({ ...PersonalData, street: e.target.value }) }} style={{ boxShadow: "none" }} style={{ boxShadow: "none" }} className="form-control" type="text" /></div>
                    </div>
                </div>
                <div className="mt-3" style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
                    <div style={{ width: "40%" }}>
                        <label>* Bo'lim:</label>
                        <div><input value={PersonalData.bulim} onChange={(e) => { setPersonalData({ ...PersonalData, bulim: e.target.value }) }} style={{ boxShadow: "none" }} className="form-control" type="text" /></div>
                    </div>
                    <div style={{ width: "40%" }}>
                        <label>* Lavozim:</label>
                        <div><input value={PersonalData.position} onChange={(e) => { setPersonalData({ ...PersonalData, position: e.target.value }) }} style={{ boxShadow: "none" }} className="form-control" type="text" /></div>
                    </div>
                </div>
                <div>
                    <label className="mt-4">* Lavozimida ish boshlagan sana: </label>
                    <div><input value={PersonalData.positionDate} onChange={(e) => { setPersonalData({ ...PersonalData, positionDate: e.target.value }) }} style={{ boxShadow: "none" }} className="form-control" type="date" /></div>
                </div>
                <div className="mt-4" style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
                    <div style={{ width: "40%" }}>
                        <label>* Ish turi:</label>
                        <div>
                            <select value={PersonalData.workType} onChange={(e) => { setPersonalData({ ...PersonalData, workType: e.target.value }) }} style={{ boxShadow: "none" }} className="form-select">
                            <option  selected >Ish turini tanlang</option>
                                <option value="Asosiy">Asosiy</option>
                                <option value="urindosh">Tashqi o'rindosh</option>
                            </select>
                        </div>
                    </div>
                    <div style={{ width: "40%" }}>
                        <label> Telefon raqami:</label>
                        <div><input value={PersonalData.phoneNumber} onChange={(e) => { setPersonalData({ ...PersonalData, phoneNumber: +e.target.value }) }} style={{ boxShadow: "none" }} className="form-control" type="number" /></div>
                    </div>
                </div>
                <div>
                <label className=" mt-5"> * Obektivka (faqat doc formatda bo'lishi kerak)</label>
                    <div><input  onChange={(e) => { setPersonalData({ ...PersonalData, obektFile: e.target.files[0] }) }} style={{ boxShadow: "none" }} className="form-control" type="file" /></div>
                </div>
            </div>
        </div>
    )
}

export default Personal;