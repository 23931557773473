import React from "react";
import {Route, Routes} from "react-router-dom"
import AdminAuth from "./adminPage/adminAuth";
import AdminMAin from "./adminPage/adminMain";
import AddUserList from "./adminPage/userList/addUserList";
import Main from "./main";
import AllComponent from "./presentEmployee/AllComponent";
import PresnetMain from "./PresentEmployeeMain/PresentMain";
import PostRaxbar from "./RahbarHodinlar/PostRaxbar";
import AddUser from "./userPage/AddUser";
import UserPage from "./userPage/userPage";
function App() {


  return (
    <Routes>
      <Route path="/" element={<AdminAuth/>}/>
      <Route path="/main/*" element={<AdminMAin/>}/>
      <Route path="/Present_main/*" element={<AllComponent/>}/>
      <Route path="/Bulim_admin/*" element={<PresnetMain/>}/>
      <Route path="/user/*" element={<UserPage/>}/>
      <Route path="/addEmployee" element={<Main/>}/>
      <Route path="/addBulim" element={<PostRaxbar/>}/>
      <Route path="/addUserList" element={<AddUserList/>}/>
    </Routes>
  );
}

export default App;
