import React,{useEffect, useState} from "react";
import { Checkbox } from 'antd';
import {
    PlusCircleOutlined,
    DeleteOutlined
} from '@ant-design/icons';

const PresentEducate = ({PresentPersonalData, setPresentPersonalData   }) =>{

    const onChange = (e) => {
        console.log(`checked = ${e.target.checked}`);
      };

      const [talim, setTalim] = useState([{
        talimOlganDavlat:"",
        talimDaraja:"",
        OTMnomi:"",
        mutahasis:"",
        endDate:"",
        checkTalim:false,
      }])

      const addTalim = ()=>{
        setTalim([...talim, {
            talimOlganDavlat:"",
            talimDaraja:"",
            OTMnomi:"",
            mutahasis:"",
            endDate:"",
            checkTalim:false
        }])       
      }

      useEffect(()=>{
        setTalim(PresentPersonalData.talimData)
      },[])

      useEffect(()=>{
        setPresentPersonalData({...PresentPersonalData, talimData:talim})
      },[talim])

      const handleTalimChange = (e, index) =>{
        const {name,  value} = e.target;
        const list = [...talim];
        list[index][name] = value;
        setTalim(list)
        // console.log(index)
    };

    const handleRemove = (index) =>{
        console.log(index);
        const list=[...talim];
        list.splice(index,1);
        setTalim(list)
    };
    
    return(
        <div className="card" style={{border:"none"}}>
            <div className="card-body" style={{border:"none"}}>
                <h4>Tamomlagan ta'lim muassasi</h4>
                
               {PresentPersonalData.talimData && PresentPersonalData.talimData.map((item,index)=>(
                <div key={index}>
                    <hr/>
                <div style={{display:"flex",width:"100%", justifyContent:"space-between"}}>
                     <div style={{width:"40%"}}>
                         <label>* Ta'lim olgan davlat</label>
                         <div><input value={item.talimOlganDavlat} onChange={e => handleTalimChange(e, index)} name="talimOlganDavlat" style={{boxShadow:"none"}} className="form-control" type="text"/></div>
                     </div>
                     <div style={{width:"40%"}}>
                         <label>* Ta'lim darajasi</label>   
                         <div><input value={item.talimDaraja} onChange={e => handleTalimChange(e, index)} name="talimDaraja" style={{boxShadow:"none"}} className="form-control" type="text"/></div>
                     </div>
                 </div>
                 <div>
                     <label className="mt-4">* Ta'lim muassasi nomi</label>
                     <div><input value={item.OTMnomi} style={{boxShadow:"none"}}  onChange={e => handleTalimChange(e, index)} name="OTMnomi" className="form-control" type="text"/></div>
                 </div>
                 <div style={{display:"flex",width:"100%", justifyContent:"space-between"}}>
                     <div style={{width:"80%"}}>
                         <label className="mt-5">* Mutahasisligi</label>
                         <div><input value={item.mutahasis} onChange={e => handleTalimChange(e, index)} name="mutahasis" style={{boxShadow:"none"}} className="form-control" type="text"/></div>
                     </div>
                     <div style={{width:"15%"}}>
                         <label className="mt-5">Tamomlagan yili</label>
                         <div><input value={item.endDate} onChange={e => handleTalimChange(e, index)} name="endDate" style={{boxShadow:"none"}} className="form-control" type="date"/></div>
                     </div>
                 </div>
                {
                    talim.length!==1 &&
                    <button style={{border:"none", width:"60px", height:"30px",borderRadius:"5px",display:"flex",alignItems:"center",justifyContent:"center",marginTop:"15px",backgroundColor:"red"}} onClick={()=>handleRemove(item)}><DeleteOutlined style={{color:"white"}}/></button>
                }
                </div>
               ))}
               <div onClick={addTalim} style={{fontSize:"18px", display:"flex", alignItems:"center", cursor:"pointer", width:"5%"}} className="mt-4">
                    <PlusCircleOutlined /> <p style={{fontSize:"17px", margin:"0 0 0 10px"}}>Qo'shish</p>
                </div>
            </div>
        </div>
    )
}

export default PresentEducate