import React,{useState} from "react";
import { Button, Col, DatePicker, Drawer, Form, Input, Row, Select, Space } from 'antd';
import { DeleteOutlined,EditOutlined,PlusOutlined} from '@ant-design/icons'
import axios from "axios";
import { BaseUrl } from "./BaseUrl";

const AddKpage = (props) =>{

    const {open, showDrawer ,onClose,personcv,personlanguages,persontalims,getBulim} = props

    const [fullname, setFullName] = useState({
        type:"2",
        name:"",
        comment:""
    })

    const addBulim = () =>{
        axios.post(BaseUrl+"/api/superAdmin/section", fullname,{
            headers: {
                "Authorization": "Bearer Bearer " + localStorage.getItem("token")
            }
        }).then(res=>{
            if(res.status == 203){
                getBulim()
                console.log(res);
            }
            console.log(res.status);
        }).catch(err=>{
            console.log(err);
        })
    }

    return(
        <div>
        <Drawer
            title="Bo'lim qo'shish"
            width={720}
            onClose={onClose}
            open={open}
            bodyStyle={{
            paddingBottom: 80,
        }}
    >
<Form layout="vertical" hideRequiredMark>
  <div style={{width:"100%"}}>
    <div><label>To'liq nomini kiriting</label></div>
    <input value={fullname.comment} onChange={(e)=>setFullName({...fullname, comment:e.target.value})} style={{boxShadow:"none"}} className="form-control" type="text"/>
  </div>
  <div className="mt-4" style={{width:"100%"}}>
    <div><label>Nomini kiriting</label></div>
    <input value={fullname.name} onChange={(e)=>setFullName({...fullname, name:e.target.value})} style={{boxShadow:"none"}} className="form-control" type="text"/>
  </div>
  <Button onClick={()=>{addBulim();onClose()}} className="mt-3" type="primary" icon={<PlusOutlined />}>
            Qo'shish
  </Button>
</Form>
</Drawer>
</div>
    )
}

export default AddKpage;