import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/js/bootstrap.bundle.min.js"
// import { createStore } from 'redux';
import App from './App';
// import { Provider } from 'react-redux';

const root = ReactDOM.createRoot(document.getElementById('root'));
// const store = createStore( window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());
root.render(
  <>
   <BrowserRouter>
      {/* <Provider store={store}> */}
        <App />
      {/* </Provider> */}
   </BrowserRouter>
  </>
);
