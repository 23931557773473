import { DeleteOutlined,LoginOutlined,PlusOutlined,DownloadOutlined} from '@ant-design/icons'
import { Breadcrumb, Layout, Menu, theme,Button } from 'antd';
import axios from 'axios';
import React,{useEffect, useState} from "react";
import {Routes, Route, Link,useNavigate} from "react-router-dom"
import { BaseUrl } from '../adminPage/BaseUrl';
import AddUser from "./AddUser"
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './userPage.css'


const { Header, Content, Footer } = Layout;

const UserPage = () =>{
    const {
        token: { colorBgContainer },
      } = theme.useToken();

      const [employe, setEmployee] = useState([])

      const [personcv, setPersoncv] = useState({})
      const [personlanguages, setPersonlanguages] = useState([])
      const [persontalims, setPersontalims] = useState([])
      const [text, setText] = useState(false)

    const [open, setOpen] = useState(false);
    const showDrawer = () => {
      setOpen(true);
    };
    const onClose = () => {
      setOpen(false);
    };

    const personCVall = () =>{
      axios.get(BaseUrl+"/api/kafedra/personcv/"+localStorage.getItem("id"),{
        headers: {
            "Authorization": "Bearer Bearer " + localStorage.getItem("token")
        }
    }).then(res=>{
        console.log(res.data);
        setEmployee(res.data)
      }).catch(err=>{
        console.log(err);
      })
    }

    useEffect(()=>{
      personCVall()
    },[])

    const getOneCv = (id) =>{
      axios.get(BaseUrl+"/api/kafedra/personcv/show/"+id,{
        headers: {
            "Authorization": "Bearer Bearer " + localStorage.getItem("token")
        }
    }).then(res=>{
        console.log(res.data);
        setPersoncv(res.data.personcv)
        setPersonlanguages(res.data.personlanguages)
        setPersontalims(res.data.persontalims)
        // console.log(personcv);
        // setEmployee(res.data)
      }).catch(err=>{
        console.log(err);
      })
    }

    const deleteCv = (id) =>{
      axios.delete(BaseUrl+"/api/kafedra/personcv/"+id,{
        headers: {
            "Authorization": "Bearer Bearer " + localStorage.getItem("token")
        }
    }).then(res=>{
      console.log(res.status);
      personCVall()
      setText(res.data.message)
      console.log(id);
    }).catch(err=>{
      console.log(err);
      setText(err.response.data.message)
    })
    }

    useEffect(() => {
      notify();
  }, [text]);

  const notify = () => {
      if (text === ""){} else if(text === "Obyektivka fayl topilmadi"){
        toast.error(text);
      }
      else{
        toast.success(text);
      }
      setText("")
  };

  const navigate = useNavigate()
  const logOut = () =>{
    localStorage.removeItem("section_id")
    localStorage.removeItem("id")
    localStorage.removeItem("username")
    localStorage.removeItem("email")
    localStorage.removeItem("userRole")
    localStorage.removeItem("token")
    navigate("/")
  }

    return(
        <Layout>
           <ToastContainer/>
        <Header style={{position: 'sticky',top: 0, zIndex: 1, width: '100%'}}>
          {/* <div style={{float: 'left', width: 120,  background: 'rgba(255, 255, 255, 0.2)', }}/> */}
          <div style={{display:"flex",justifyContent:"space-between"}}>
          <Menu theme="dark"mode="horizontal"defaultSelectedKeys={['1']} style={{height:"64px"}}>
          <div style={{display:"flex",boxSizing:"border-box"}}>
            <img style={{width:"50px", height:"50px",marginTop:"10px",borderRadius:"50%"}} src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/77/Emblem_of_Uzbekistan.svg/250px-Emblem_of_Uzbekistan.svg.png"/>
            <p style={{marginLeft:"10px",fontSize:"20px"}}>Ma'lumotlar bazasi</p>
         </div>
              <Menu.Item style={{marginLeft:"40px"}}  className="menu-main-item" key="1">
                            <Link to="/user/">
                              <div className="in_menu_item">
                                      <div className="for_menu_text">Pedagoglar ro'yxati</div>
                              </div>
                            </Link>
              </Menu.Item>
          </Menu>
          <div style={{display:"flex",justifyContent:"space-between",width:"230px"}}>
              <div>
                  <p style={{color:"white"}}>{localStorage.getItem("username")}</p>
                </div>
                <div onClick={logOut} style={{color:"white",display:"flex",alignItems:"center",cursor:"pointer"}}>
                  <p style={{}}>Log Out</p>
                  <LoginOutlined style={{fontSize:"20px",marginTop:"-15px",marginLeft:"10px"}}/>
                </div>
              </div>
              </div>
        </Header>
        <Content className="site-layout"style={{padding: '0 50px', }}>
        <Breadcrumb style={{margin: '16px 0',}}> </Breadcrumb>
          <div style={{ padding: 24, height:"87vh",background: colorBgContainer,}}>
            <div style={{width:"100%", display:"flex",justifyContent:"end",margin:"0 0 10px 0"}}>
                <Link to="/addEmployee">
                <Button type="primary" icon={<PlusOutlined />}>
                     Hodim qo'shish
                </Button>
                </Link>
            </div>
          <table class="table table-bordered">
            <thead>
                <tr>
                    <th scope="col">№</th>
                    <th scope="col">Familiya</th>
                    <th scope="col">Lavozim</th>
                    <th scope="col">Oxirgi yangilanish</th>
                    <th scope="col">Ma'lumotnoma</th>
                    <th scope="col" style={{textAlign:"center"}}>Amallar</th>
                </tr>
            </thead>
            <tbody>
            {
                    employe && employe.map((item, index)=>(
                        <tr className='align-middle' key={index}>
                            <td  scope="col">{item.id}</td>
                            <td  style={{cursor:"pointer"}} scope="col" onClick={()=>getOneCv(item.id)}><p onClick={()=>showDrawer()} className="text-primary m-0"><img style={{width:"60px",height:"60px"}} src={BaseUrl+"/storage/"+item.foto3x4} alt="user"/> {item.fullName}</p></td>
                            <td className='aligin' scope="col">{item.position}</td>
                            <td  scope="col">{item.updated_at.slice(0,10)}</td>
                            <td  scope="col"><a href={BaseUrl+"/storage/"+item.obektFile} target={'_blank'}><button className="btn btn-primary" style={{border:"none",display:"flex",alignItems:"center"}}><DownloadOutlined /> Yuklab olish</button></a></td>
                            <td  scope="col" >
                                <div className='trash_stayle' style={{width:"35px", height:"35px", borderRadius:"5px",display:"flex", alignItems:"center", justifyContent:"center",backgroundColor:"red",color:"white", fontSize:"20px",marginLeft:"5px",cursor:"pointer"}} onClick={()=>deleteCv(item.id)}><DeleteOutlined /></div>
                            </td>
                     </tr>
                    ))
                }
            </tbody>
          </table>
          </div>
        </Content>
        <AddUser personcv={personcv} personlanguages={personlanguages} persontalims={persontalims} showDrawer={showDrawer} onClose={onClose} open={open}/>
      </Layout>

)}

export default UserPage;

// style={{margin:"auto 0",display:"flex", justifyContent:"center",height:'100%'}}