import { DeleteOutlined,EditOutlined,PlusOutlined,DownloadOutlined} from '@ant-design/icons'
import { Breadcrumb, Layout, Menu, theme,Button } from 'antd';
import axios from 'axios';
import React,{useEffect, useState}  from "react";
import {Routes, Route, Link} from "react-router-dom"
import { BaseUrl } from '../adminPage/BaseUrl';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactPaginate from "react-paginate"
import DeteilOddiyHodim from './DeteilOddiyHodim';

const  MainOddiyHodim = () =>{

     
    const {
        token: { colorBgContainer },
      } = theme.useToken();

      const [employe, setEmployee] = useState([])

      const [personcv, setPersoncv] = useState({})
      const [personlanguages, setPersonlanguages] = useState([])
      const [persontalims, setPersontalims] = useState([])
      const [text, setText] = useState(false)

    const [open, setOpen] = useState(false);
    const showDrawer = () => {
      setOpen(true);
    };
    const onClose = () => {
      setOpen(false);
    };

    const personCVall = () =>{
     if (localStorage.getItem("userRole")==0) {
      axios.get(BaseUrl+"/api/superAdmin/xodimcv",{
        headers: {
            "Authorization": "Bearer Bearer " + localStorage.getItem("token")
        }
    }).then(res=>{
        console.log(res.data);
        setEmployee(res.data)
      }).catch(err=>{
        console.log(err);
      })
     }
     else if(localStorage.getItem("userRole")==2) {
      axios.get(BaseUrl+"/api/bulim/xodimcv/"+localStorage.getItem("id"),{
        headers: {
            "Authorization": "Bearer Bearer " + localStorage.getItem("token")
        }
    }).then(res=>{
        console.log(res.data);
        setEmployee(res.data)
      }).catch(err=>{
        console.log(err);
      })
     }
    }

    useEffect(()=>{
      personCVall()
    },[])

    const getOneCv = (id) =>{
      axios.get(BaseUrl+"/api/bulim/xodimcv/show/"+id,{
        headers: {
            "Authorization": "Bearer Bearer " + localStorage.getItem("token")
        }
    }).then(res=>{
        console.log(res.data);
        setPersoncv(res.data.xodimcv)
        setPersonlanguages(res.data.xodimlanguages)
        setPersontalims(res.data.xodimtalims)
        // console.log(personcv);
        // setEmployee(res.data)
      }).catch(err=>{
        console.log(err);
      })
    }

    const deleteCv = (id) =>{
      axios.delete(BaseUrl+"/api/bulim/xodimcv/"+id,{
        headers: {
            "Authorization": "Bearer Bearer " + localStorage.getItem("token")
        }
    }).then(res=>{
      console.log(res.status);
      personCVall()
      setText(res.data.message)
      console.log(id);
    }).catch(err=>{
      console.log(err);
      setText(err.response.data.message)
    })
    }

    useEffect(() => {
      notify();
  }, [text]);

  const notify = () => {
      if (text === ""){} else if(text === "Obyektivka fayl topilmadi"){
        toast.error(text);
      }
      else{
        toast.success(text);
      }
      setText("")
  };



  const [currentItems, setCurrentItems] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);
    const itemsPerPage = 15;

    useEffect(() => {
        const endOffset = itemOffset + itemsPerPage;
        // console.log(Loading items from ${itemOffset} to ${endOffset});
            setCurrentItems(employe && employe.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(employe.length / itemsPerPage));
    }, [itemOffset, itemsPerPage, employe]);


    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % employe.length;
        setItemOffset(newOffset);
    };


    return(
        <div>
             <div style={{width:"100%", display:"flex",justifyContent:"space-between",margin:"0 0 10px 0"}}>
             {
                localStorage.getItem("userRole")==0 ? <Link to={BaseUrl+"/xodimcv/export"}>
                <Button type="primary" icon={<DownloadOutlined style={{fontSize:"18px"}}/>}>
                    excel
                </Button>
                </Link>:""
             }
                <Link to="/Present_main">
                <Button type="primary" icon={<PlusOutlined />}>
                    Xodim qo'shish
                </Button>
                </Link>
            </div>
            <table class="table table-bordered">
            <thead>
                <tr>
                    <th scope="col">ID</th>
                    <th scope="col">Familiya</th>
                    <th scope="col">Bo'lim nomi</th>
                    <th scope="col">Lavozim</th>
                    <th scope="col">Oxirgi yangilanish</th>
                    <th scope="col">Ma'lumotnoma</th>
                    <th scope="col" style={{textAlign:"center"}}>Amallar</th>
                </tr>
            </thead>
            <tbody>
            {
                    currentItems && currentItems.map((item, index)=>(
                        <tr className='align-middle' key={index}>
                            <td scope="col">{item.id}</td>
                            <td  style={{cursor:"pointer"}} scope="col" onClick={()=>getOneCv(item.id)}><p onClick={()=>showDrawer()} className="text-primary m-0"><img style={{width:"60px",height:"60px"}} src={BaseUrl+"/storage/"+item.foto3x4} alt="user"/> {item.fullName}</p></td>
                            <td scope="col">{item.bulim}</td>
                            <td scope="col">{item.position}</td>
                            <td scope="col">{item.updated_at.slice(0,10)}</td>
                            <td scope="col"><a href={BaseUrl+"/storage/"+item.obektFile} target={'_blank'}><button className="btn btn-primary" style={{border:"none",display:"flex",alignItems:"center"}}><DownloadOutlined /> Yuklab olish</button></a></td>
                            <td  scope="col" >
                                <div className='trash_stayle' style={{margin:"0 auto", width:"35px", height:"35px", borderRadius:"5px",display:"flex", alignItems:"center", justifyContent:"center",backgroundColor:"red",color:"white", fontSize:"20px",marginLeft:"5px",cursor:"pointer"}} onClick={()=>deleteCv(item.id)}><DeleteOutlined /></div>
                            </td>
                     </tr>
                    ))
                }
            </tbody>
            </table>

            <div className="my-pagination">
                <ReactPaginate
                    breakLabel="..."
                    nextLabel=" >"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={2}
                    pageCount={pageCount}
                    previousLabel="<"
                    renderOnZeroPageCount={null}
                    containerClassName="pagination"
                    pageLinkClassName="page-num"
                    previousLinkClassName="page-num"
                    nextLinkClassName="page-num"
                    activeLinkClassName="active"
                />
            </div>
            <DeteilOddiyHodim personcv={personcv} personlanguages={personlanguages} persontalims={persontalims} showDrawer={showDrawer} onClose={onClose} open={open}/>
        </div>
    )
}

export default MainOddiyHodim;