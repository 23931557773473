import React, {useEffect, useState} from "react";
import { Radio } from 'antd';

const Career = ({PersonalData, setPersonalData}) =>{

    const [value, setValue] = useState("");
    const [value2, setValue2] = useState("");

    let a = PersonalData.pedagok, b = PersonalData.rahbar, c = PersonalData.boshqa, d = PersonalData.rektor;
    let birinchi = (a + b + c + d) / 12

    let ikkinchi = (a + b + c + d) % 12

    const onChange = (e) => {
        // console.log( e.target.value);
        setPersonalData({...PersonalData, lavozimgaTayin:e.target.value})
    };
    const onChange2 = (e) => {
        // console.log( e.target.value);
        setPersonalData({...PersonalData, vazirlikLavozim:e.target.value})
    };

    setTimeout(()=>{

    },200)

    return(
        <div className="card" style={{border:"none"}}>
            <div className="card-body" style={{border:"none"}}>
               <div style={{width:"100%", display:"flex", justifyContent:"space-between"}}>
                    <h4>Ish tajribasi (Joriy lavozimgacha)</h4>
                    <h4>Jami ish tajribasi: {Math.floor(birinchi ) + " yil "}{ikkinchi + " oy"}</h4>
               </div>
                <hr/>
                <div style={{display:"flex",width:"100%", justifyContent:"space-between"}}>
                    <div style={{width:"40%"}}>
                        <label>Pedagogik ish tajribasi (oyda)</label>
                        <div><input value={PersonalData.pedagok} onChange={(e)=>setPersonalData({...PersonalData, pedagok:+e.target.value})} style={{boxShadow:"none"}} className="form-control" type="number"/></div>
                    </div>
                    <div style={{width:"40%"}}>
                        <label>Rahbarlik ish tajribasi (oyda)</label>
                        <div><input value={PersonalData.rahbar} onChange={(e)=>setPersonalData({...PersonalData, rahbar:+e.target.value})} className="form-control" type="number"/></div>
                    </div>
                </div>
                <div style={{display:"flex",width:"100%", justifyContent:"space-between"}}>
                    <div style={{width:"40%"}}>
                        <label className="mt-5">Boshqa joylarda ish tajribasi (oyda)</label>
                        <div><input value={PersonalData.boshqa} onChange={(e)=>setPersonalData({...PersonalData, boshqa:+e.target.value})} className="form-control" type="number"/></div>
                    </div>
                    <div style={{width:"40%"}}>
                        <label className="mt-5">Rektorlikdagi ish tajribasi (oyda)</label>
                        <div><input value={PersonalData.rektor} onChange={(e)=>setPersonalData({...PersonalData, rektor:+e.target.value})} className="form-control" type="number"/></div>
                    </div>
                </div>
                <div>
                    <label className="mt-4">* Lavozimga tayillanguniga qadar egallagan lavozimi</label>
                    <div>
                        <Radio.Group onChange={onChange} value={PersonalData.lavozimgaTayin} className="mt-2">
                            <Radio value="Prorektor">Prorektor</Radio>
                            <Radio value="Dekan">Dekan</Radio>
                            <Radio value="Kafedra mudiri">Kafedra mudiri</Radio>
                            <Radio value="Vazirlik va idora">Vazirlik va idora</Radio>
                            <Radio value="Professor o'qituvchi">Professor o'qituvchi</Radio>
                            <Radio value="Mahalliy davlat hokimyat idoralari">Mahalliy davlat hokimyat idoralari</Radio>
                            <Radio value="Hususiy sektor">Hususiy sektor</Radio>
                            <Radio value="OTM Bo'lim boshlig'i">OTM Bo'lim boshlig'i</Radio>
                            <Radio value="Dekan o'rinbosari">Dekan o'rinbosari</Radio>
                            <Radio value="Rektor">Rektor</Radio>
                            <Radio value="Professor">Professor</Radio>
                            <Radio value="Dotsent">Dotsent</Radio>
                            <Radio value="Katta o'qituvchi">Katta o'qituvchi</Radio>
                            <Radio value="O'qituvchi">O'qituvchi</Radio>
                        </Radio.Group>
                    </div>
                </div>

                <div>
                    <label className="mt-4">* Vazirlik va idoradagi rahbar lavozimga egaligi</label>
                    <div>
                        <Radio.Group onChange={onChange2} value={PersonalData.vazirlikLavozim} className="mt-2">
                            <Radio value="Avval">Avval</Radio>
                            <Radio value="Hozirda">Hozirda</Radio>
                            <Radio value="Lavozimga ega emas">Lavozimga ega emas</Radio>
                        </Radio.Group>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Career;