import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { BaseUrl } from "../BaseUrl";
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AddUserList = () =>{

    const navigate = useNavigate()
    const [text, setText] = useState(false)
    const [users, setUsers] = useState({
        section_id:"",
        username:"",
        email:"",
        password:"",
        role:""
    })

    const [bulimList, setBulimList] = useState([])

    const getBulim = () =>{
        axios.get(BaseUrl+"/api/superAdmin/section/filter/"+users.role,{
            headers: {
                "Authorization": "Bearer Bearer " + localStorage.getItem("token")
            }
        }).then(res=>{
            console.log(res.data.sections);
            setBulimList(res.data.sections)
        }).catch(err=>{
            console.log(err);
        })
    }

    const addUser = () =>{
        axios.post(BaseUrl+"/api/superAdmin/users",users,{
            headers: {
                "Authorization": "Bearer Bearer " + localStorage.getItem("token")
            }
        }).then(res=>{
            console.log(res);
            if (res.status == 200) {
                navigate("/main/userList")
            }
        }).catch(err=>{
            console.log(err);
            setText(err.response.data.message)
        })
    }

    useEffect(()=>{
        getBulim()
    },[users])

    useEffect(() => {
        notify();
    }, [text]);

    const notify = () => {
        if (text === ""){} else toast.error(text);
        setText("")
    };

    console.log(users);

    return(
        <div className="container">
            <ToastContainer/>
            <div className="row">
                <div style={{height:"100%",display:"flex",marginTop:"80px"}} className="col-8 offset-2">
                <div className="card" style={{border:"none",height:"80vh",width:"100%",}}>
                  <div className="card-body" style={{border:"none"}}>
                <h4>Tamomlagan oliy ta'lim muassasi</h4>
                <div>
                    <hr/>
                <div style={{display:"flex",width:"100%", justifyContent:"space-between"}}>
                     <div style={{width:"40%"}}>
                         <label>Foydalanuvchu guruhini tanlang</label>
                         <div>
                            <select className="form-select" onChange={(e)=>setUsers({...users, role:e.target.value})}>
                            <option selected>Guruhini tanlang</option>
                            <option value="1">Fakultet</option>
                                <option value="2">Kafedra</option>
                                <option value="3">Bo'lim</option>
                            </select>
                         </div>
                     </div>
                     <div style={{width:"40%"}}>
                         <label>* Bo'limni tanlang</label>   
                         <select className="form-select" onChange={(e)=>setUsers({...users, section_id:e.target.value})}>
                            <option selected>Bo'limni tanlang</option>
                                {
                                    bulimList && bulimList.map((item,index)=>(
                                        <option value={item.id}>{item.name}</option>
                                    ))
                                }
                            </select>
                     </div>
                 </div>
                 <div>
                     <label className="mt-4">* Foydalanuvchi nomi</label>
                     <div><input value={users.username} style={{boxShadow:"none"}}  onChange={(e)=>setUsers({...users, username:e.target.value})} name="OTMnomi" className="form-control" type="text"/></div>
                 </div>
                   <div style={{display:"flex",width:"100%", justifyContent:"space-between"}}>
                        <div style={{width:"40%"}}>
                            <label className="mt-5">* Email</label>
                            <div><input value={users.email} onChange={(e)=>setUsers({...users, email:e.target.value})} name="mutahasis" style={{boxShadow:"none"}} className="form-control" type="text"/></div>
                        </div>
                        <div style={{width:"40%"}}>
                            <label className="mt-5">Parol</label>
                            <div><input value={users.password} onChange={(e)=>setUsers({...users, password:e.target.value})} name="endDate" style={{boxShadow:"none"}} className="form-control" type="text"/></div>
                        </div>
                    </div>
                    <div>
                       <div style={{display:"flex",justifyContent:"center",marginTop:"20px"}}><button onClick={addUser} className="btn btn-success" style={{boxShadow:"none", width:"400px",color:"white"}}>Qo'shish</button></div>
                    </div>
                </div>
                </div>
        </div>
                </div>
            </div>
        </div>
    )
}

export default AddUserList;