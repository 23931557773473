import React,{useEffect, useState} from "react";
import { DeleteOutlined,EditOutlined,PlusOutlined} from '@ant-design/icons'
import { BaseUrl } from "./BaseUrl";
import axios from "axios";
import AddFpage from "./addFpage";
import { Button, Col, DatePicker, Drawer, Form, Input, Row, Select, Space } from 'antd';
import ReactPaginate from "react-paginate"

const Fakultet = () =>{

    const [employe, setEmployee] = useState([]) 
    const [open, setOpen] = useState(false);
    const showDrawer = () => {
      setOpen(true);
    };
    const onClose = () => {
      setOpen(false);
    };


    const getBulim = () =>{
        axios.get(BaseUrl+"/api/superAdmin/section/filter/1",{
            headers: {
                "Authorization": "Bearer Bearer " + localStorage.getItem("token")
            }
        }).then(res=>{
            console.log(res.data.sections);
            setEmployee(res.data.sections)
        }).catch(err=>{
            console.log(err);
        })
    }
    const deleteBulim = (id)=>{
        axios.delete(BaseUrl+"/api/superAdmin/section/"+id,{
            headers: {
                "Authorization": "Bearer Bearer " + localStorage.getItem("token")
            }
        }).then(res=>{
            console.log(res);
            getBulim()
        }).catch(err=>{
            console.log(err);
        })
    }

    useEffect(()=>{
        getBulim()
    },[])


    const [currentItems, setCurrentItems] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);
    const itemsPerPage = 13;

    useEffect(() => {
        const endOffset = itemOffset + itemsPerPage;
        // console.log(Loading items from ${itemOffset} to ${endOffset});
            setCurrentItems(employe && employe.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(employe.length / itemsPerPage));
    }, [itemOffset, itemsPerPage, employe]);


    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % employe.length;
        setItemOffset(newOffset);
    };



    return(
       <div>
        <div style={{width:"100%",display:"flex",justifyContent:"end"}}>
            <Button className="mb-3" onClick={showDrawer} type="primary" icon={<PlusOutlined />}>
                Fakultet qo'shish
            </Button>
        </div>
         <table class="table table-bordered">
            <thead>
            <tr>
                    <th scope="col">ID</th>
                    <th scope="col">To'liq nomi</th>
                    <th scope="col">Nomi</th>
                    <th style={{textAlign:"center"}} scope="col">Amallar</th>
                </tr>   
            </thead>
            <tbody>
            {
                    currentItems && currentItems.map((item, index)=>(
                        <tr key={index}>
                            <td scope="col">{item.id}</td>
                            <td scope="col">{item.comment}</td>
                            <td scope="col">{item.name}</td>
                            <td scope="col" style={{display:"flex",alignItems:"center", justifyContent:"center"}}>
                            {/* <div className="border" style={{width:"35px", height:"35px", borderRadius:"5px",display:"flex", alignItems:"center", justifyContent:"center", fontSize:"20px",cursor:"pointer"}}><EditOutlined /></div> */}
                            <div onClick={()=>deleteBulim(item.id)} style={{width:"35px", height:"35px", borderRadius:"5px",display:"flex", alignItems:"center", justifyContent:"center",backgroundColor:"red",color:"white", fontSize:"20px",marginLeft:"5px",cursor:"pointer"}}><DeleteOutlined /></div>
                            </td>
                     </tr>
                    ))
                }
            </tbody>
        </table>
        <div className="my-pagination">
           <ReactPaginate
               breakLabel="..."
               nextLabel=" >"
               onPageChange={handlePageClick}
               pageRangeDisplayed={2}
               pageCount={pageCount}
               previousLabel="<"
               renderOnZeroPageCount={null}
               containerClassName="pagination"
               pageLinkClassName="page-num"
               previousLinkClassName="page-num"
               nextLinkClassName="page-num"
               activeLinkClassName="active"
           />
       </div>
        <AddFpage showDrawer={showDrawer} onClose={onClose} open={open} getBulim={getBulim}/>
       </div>
    )
}

export default Fakultet;