import React, { useState, useEffect } from "react";
import { Checkbox, Radio } from 'antd';
import {
    PlusCircleOutlined,
    DeleteOutlined
} from '@ant-design/icons';

const RaxbarAddition = ({ RaxbarPersonalData, setRaxbarPersonalData }) => {
    const [value, setValue] = useState("");

    const onChange2 = (e) => {
        console.log('radio checked', e.target.value);
        setValue(e.target.value);
        setRaxbarPersonalData({...RaxbarPersonalData, ilmiyUnvon:e.target.value})
    };
    const [ilmiyDaraja, setIlmiyDaraja] = useState(false)
    const onChange = (e) => {
        setIlmiyDaraja(e.target.checked)
        
    };

    // const [sertifikat, setSertifikat] = useState([{
    //     file:""
    // }])
    const [languageLevel, setLanguageLevel] = useState([{
        language: "",
        degree: "",
        file:""
    }])

    const addLanguage = () => {
        setLanguageLevel([...languageLevel, {
            language: "",
            degree: "",
            file:""
        }])

    }
     useEffect(()=>{
        setLanguageLevel(RaxbarPersonalData.languageData)
      },[])


      useEffect(()=>{
        setRaxbarPersonalData({...RaxbarPersonalData, languageData:languageLevel})
      },[languageLevel])

    const handleInputLanguage = (e, index) =>{
        const {name,  value} = e.target;
        const list = [...languageLevel];
        list[index][name] = value;
        setLanguageLevel(list)
    };

    const handleInputFile = (e,index) =>{
        // languageLevel.map((item)=>(item.file = e.target.files[0]))
        languageLevel[index].file = e.target.files[0]
        console.log(RaxbarPersonalData);
    }
    const handleRemove = (index) =>{
        const list=[...languageLevel];
        list.splice(index,1);
        setLanguageLevel(list)
    };
    return (
        <>
            <div className="card" style={{ border: "none" }}>
                <div className="card-body" style={{ border: "none" }}>
                    <h4>Xorijiy til bilish darajasi</h4>
                    <hr />
                    {RaxbarPersonalData.languageData && RaxbarPersonalData.languageData.map((item, index) => (
                        <div key={index}>
                            <div className="mt-4" style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
                                <div style={{ width: "40%" }}>
                                    <label>* Til nomi</label>
                                    <div>
                                        <select onClick={(e) => handleInputLanguage(e, index)} style={{ boxShadow: "none" }} className="form-select"  name="language">
                                           <option disabled selected>Tilni tanlang</option>
                                            <option value="Fransuz tili">Fransuz tili</option>
                                            <option value="Ingilis tili">Ingilis tili</option>
                                            <option value="Nemis tili">Nemis tili</option>
                                            <option value="Ispan tili">Ispan tili</option>
                                            <option value="Italian tili">Italian tili</option>
                                            <option value="Arab tili">Arab tili</option>
                                            <option value="Xitoy tili">Xitoy tili</option>
                                            <option value="Yapon tili">Yapon tili</option>
                                            <option value="Koreys tili">Koreys tili</option>
                                            <option value="Turk tili">Turk tili</option>
                                        </select>
                                    </div>
                                </div>
                                <div style={{ width: "40%" }}>
                                    <label>* Bilish darajasi</label>
                                    <div>
                                        <select  onClick={(e) => handleInputLanguage(e, index)} style={{ boxShadow: "none" }} className="form-select"  name="degree">
                                            <option disabled selected>Darajani tanlang</option>
                                            <option value="B1">B1</option>
                                            <option value="B2">B2</option>
                                            <option value="C1">C1</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <label className="mt-5">Sertifikatni yuklang</label>
                                <div><input onChange={(e) => handleInputFile(e, index)} name="fileLanguage" style={{ boxShadow: "none" }} className="form-control " type="file" /></div>
                            </div>
                            {
                                    languageLevel.length!==1 &&
                                    <button style={{border:"none", width:"60px", height:"30px",borderRadius:"5px",display:"flex",alignItems:"center",justifyContent:"center",marginTop:"15px",backgroundColor:"red"}} onClick={()=>handleRemove(item)}><DeleteOutlined style={{color:"white"}}/></button>
                                }
                        </div>
                    ))}
                    <div onClick={()=>{addLanguage()}} style={{ fontSize: "18px", display: "flex", alignItems: "center", cursor: "pointer", width: "5%" }} className="mt-4">
                        <PlusCircleOutlined /> <p style={{ fontSize: "17px", margin: "0 0 0 10px" }}>Qo'shish</p>
                    </div>
                </div>
            </div>
            <hr />
            <div className="card" style={{ border: "none" }}>
                <div className="card-body" style={{ border: "none" }}>
                    <h4>Mukofotlanganligi</h4>
                    <hr />
                    <div>
                        <label className="mt-3">Davlat mukofoti</label>
                        <div><input value={RaxbarPersonalData.davlatMukofoti} onChange={(e)=>setRaxbarPersonalData({...RaxbarPersonalData, davlatMukofoti:e.target.value})} style={{ boxShadow: "none" }} className="form-control" type="text" /></div>
                    </div>
                    <div>
                        <label className="mt-5">Idoraviy mukofoti</label>
                        <div><input value={RaxbarPersonalData.idoraviyMukofot} onChange={(e)=>setRaxbarPersonalData({...RaxbarPersonalData, idoraviyMukofot:e.target.value})} style={{ boxShadow: "none" }} className="form-control" type="text" /></div>
                    </div>
                </div>
            </div>
            <hr />
            <div className="card " style={{ border: "none" }}>
                <div className="card-body" style={{ border: "none" }}>
                    <h4>Ilmiy daraja</h4>
                    <hr />
                    <div>
                        <Checkbox className="mt-3" onChange={onChange}>Ilmiy darajasiz</Checkbox>
                    </div>
                    {ilmiyDaraja ? 
                   ""
                    : <>
                    <div className="mt-4" style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
                        <div style={{ width: "40%" }}>
                            <label> Fan nomzodi (PhD) olgan hudud</label>
                            <div>
                                <select value={RaxbarPersonalData.fandomzodiPhDhudud} onChange={(e)=>setRaxbarPersonalData({...RaxbarPersonalData, fandomzodiPhDhudud:e.target.value})} style={{ boxShadow: "none" }} className="form-select">
                                    <option >Hududni tanlang</option>
                                    <option value="Mahalliy">Mahalliy</option>
                                    <option value="Xorijiy">Xorijiy</option>
                                </select>
                            </div>
                        </div>
                        <div style={{ width: "40%" }}>
                            <label> Fan dokdori (DSc) olgan hudud</label>
                            <div>
                                <select value={RaxbarPersonalData.fandomzodiDschudud} onChange={(e)=>setRaxbarPersonalData({...RaxbarPersonalData, fandomzodiDschudud:e.target.value})} style={{ boxShadow: "none" }} className="form-select">
                                   <option >Hududni tanlang</option>
                                   <option value="Mahalliy">Mahalliy</option>
                                   <option value="Xorijiy">Xorijiy</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="mt-4" style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
                        <div style={{ width: "40%" }}>
                            <label>Fan nomzodi (PhD)</label>
                            <div>
                                <input className="form-control" type="text" value={RaxbarPersonalData.fandomzodiPhD} onChange={(e)=>setRaxbarPersonalData({...RaxbarPersonalData, fandomzodiPhD:e.target.value})} style={{ boxShadow: "none" }}/>
                            </div>
                        </div>
                        <div style={{ width: "40%" }}>
                            <label>Fan dokdori (DSc)</label>
                            <div>
                                <input type="text" value={RaxbarPersonalData.fandomzodiDsc} onChange={(e)=>setRaxbarPersonalData({...RaxbarPersonalData, fandomzodiDsc:e.target.value})} style={{ boxShadow: "none" }} className="form-control"/>
                            </div>
                        </div>
                    </div>
                    <div className="mt-3" style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
                    <div style={{ width: "40%" }}>
                            <label> Diplom seriya va raqami 1 :</label>
                            <div style={{width:"100%", display:"flex",justifyContent:"space-between"}}>
                                <input value={RaxbarPersonalData.fandomzodiPhDseriya} onChange={(e)=>setRaxbarPersonalData({...RaxbarPersonalData, fandomzodiPhDseriya:e.target.value})} style={{ boxShadow: "none", width:"60%"}} className="form-control" type="text" />
                                <div style={{ width: "35%" }}>
                                    <div>
                                        <input value={RaxbarPersonalData.fandomzodiPhDdate} onChange={(e)=>setRaxbarPersonalData({...RaxbarPersonalData, fandomzodiPhDdate:e.target.value})} style={{ boxShadow: "none" }} className="form-control" type="date" />
                                    </div>
                                </div>
                            </div>
                        </div>   
                        <div style={{ width: "40%" }}>
                            <label> Diplom seriya va raqami 2 :</label>
                            <div style={{width:"100%", display:"flex",justifyContent:"space-between"}}>
                                <input value={RaxbarPersonalData.fandomzodiDscseriya} onChange={(e)=>setRaxbarPersonalData({...RaxbarPersonalData, fandomzodiDscseriya:e.target.value})} style={{ boxShadow: "none", width:"60%"}} className="form-control" type="text" />
                                <div style={{ width: "35%" }}>
                                    <div>
                                        <input value={RaxbarPersonalData.fandomzodiDscdate} onChange={(e)=>setRaxbarPersonalData({...RaxbarPersonalData, fandomzodiDscdate:e.target.value})} style={{ boxShadow: "none" }} className="form-control" type="date" />
                                    </div>
                                </div>
                            </div>
                        </div>   
                    </div>
                    </>
                }
                    <h4 style={{ margin: "30px 0 20px 0" }}>Ilmiy unvoni</h4>
                    <hr />
                    <div>
                        <div>
                            <Radio.Group onChange={onChange2} value={RaxbarPersonalData.ilmiyUnvon} className="mt-2">
                                <Radio value="Professor">Professor</Radio>
                                <Radio value="Dotsent">Dotsent</Radio>
                                <Radio value="Unvonsiz">Unvonsiz</Radio>
                            </Radio.Group>
                        </div>
                    </div>
                    <h4 style={{ margin: "30px 0 20px 0" }}>H Index</h4>
                    <hr />
                    <div style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
                        <div style={{ width: "10%" }}>
                            <label>* Index</label>
                            <div><input value={RaxbarPersonalData.Hindex} onChange={(e)=>setRaxbarPersonalData({...RaxbarPersonalData, Hindex:e.target.value})} style={{ boxShadow: "none" }} className="form-control" type="text" /></div>
                        </div>
                        <div style={{ width: "85%" }}>
                            <label>Google scholar</label>
                            <div><input value={RaxbarPersonalData.googleScholar} onChange={(e)=>setRaxbarPersonalData({...RaxbarPersonalData, googleScholar:e.target.value})} placeholder="Link kiriting (https://) bilan" style={{ boxShadow: "none" }} className="form-control" type="text" /></div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default RaxbarAddition;