import { DeleteOutlined,EditOutlined,PlusOutlined,DownloadOutlined} from '@ant-design/icons'
import { Breadcrumb, Layout, Menu, theme,Button } from 'antd';
import axios from 'axios';
import React,{useEffect, useState}  from "react";
import {Routes, Route, Link} from "react-router-dom"
import { BaseUrl } from '../BaseUrl';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactPaginate from "react-paginate"
// import AddUser from '../userPage/AddUser';

const UserList = () =>{


    const {
        token: { colorBgContainer },
      } = theme.useToken();

      const [employe, setEmployee] = useState([])

      const [personcv, setPersoncv] = useState({})
      const [personlanguages, setPersonlanguages] = useState([])
      const [persontalims, setPersontalims] = useState([])
      const [text, setText] = useState(false)


    const personCVall = () =>{
        axios.get(BaseUrl+"/api/superAdmin/users",{
            headers: {
                "Authorization": "Bearer Bearer " + localStorage.getItem("token")
            }
         }).then(res=>{
            console.log(res);
            setEmployee(res.data)
          }).catch(err=>{
            console.log(err);
          })
    }

  const deleteCv = (id) =>{
    axios.delete(BaseUrl+"/api/superAdmin/users/"+id,{
      headers: {
          "Authorization": "Bearer Bearer " + localStorage.getItem("token")
      }
  }).then(res=>{
    console.log(res);
    if (res.status == 200) {
        personCVall()
        setText(res.data.message)
    }
    console.log(res.data.message);
  }).catch(err=>{
    console.log(err);
    setText(err.response.data.message)
  })
  }

  useEffect(()=>{
    personCVall()
  },[])



  const notify = () => {
      if (text === ""){} 
      else toast.success(text);
      setText("")
  };

  useEffect(() => {
    notify();
}, [text]);
  
  const [currentItems, setCurrentItems] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);
    const itemsPerPage = 13;

    useEffect(() => {
        const endOffset = itemOffset + itemsPerPage;
        // console.log(Loading items from ${itemOffset} to ${endOffset});
            setCurrentItems(employe && employe.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(employe.length / itemsPerPage));
    }, [itemOffset, itemsPerPage, employe]);


    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % employe.length;
        setItemOffset(newOffset);
    };






    return (
        <div>
             <ToastContainer/>
        <div style={{width:"100%", display:"flex",justifyContent:"end",margin:"0 0 10px 0"}}>
           <Link to="/addUserList">
           <Button type="primary" icon={<PlusOutlined />}>
               Foydalanuvchi qo'shish
           </Button>
           </Link>
       </div>
       <table class="table table-bordered">
       <thead>
           <tr>
               <th scope="col">ID</th>
               <th scope="col">Ism familiya</th>
               <th scope="col">Bo'lim</th>
               <th scope="col">Email</th>
               <th scope="col">Parol</th>
               <th scope="col">Amallar</th>
           </tr>
       </thead>
       <tbody>
       {
               currentItems && currentItems.map((item, index)=>(
                <>
               
               <tr key={index}>
                       <td scope="col">{item.id}</td>
                       <td>{item.username}</td>
                       <td scope="col">{item.section}</td>
                       <td scope="col">{item.email}</td>
                       <td scope="col">{item.password_visable}</td>
                       {/* <td scope="col"><a href={BaseUrl+"/storage/"+item.obektFile} target={'_blank'}><button className="btn btn-primary" style={{border:"none",display:"flex",alignItems:"center"}}><DownloadOutlined /> Yuklab olish</button></a></td> */}
                       <td scope="col" style={{display:"flex",alignItems:"center", justifyContent:"center"}}>
                       {/* <div className="border" style={{width:"35px", height:"35px", borderRadius:"5px",display:"flex", alignItems:"center", justifyContent:"center", fontSize:"20px",cursor:"pointer"}}><EditOutlined /></div> */}
                           <div style={{width:"35px", height:"35px", borderRadius:"5px",display:"flex", alignItems:"center", justifyContent:"center",backgroundColor:"red",color:"white", fontSize:"20px",marginLeft:"5px",cursor:"pointer"}} onClick={()=>deleteCv(item.id)}><DeleteOutlined /></div>
                       </td>
                </tr>
                </>
               ))
           }
       </tbody>
       </table>

       <div className="my-pagination">
           <ReactPaginate
               breakLabel="..."
               nextLabel=" >"
               onPageChange={handlePageClick}
               pageRangeDisplayed={2}
               pageCount={pageCount}
               previousLabel="<"
               renderOnZeroPageCount={null}
               containerClassName="pagination"
               pageLinkClassName="page-num"
               previousLinkClassName="page-num"
               nextLinkClassName="page-num"
               activeLinkClassName="active"
           />
       </div>
   </div>
    )
}

export default UserList;