import { useState } from 'react';
import { Button, message, Steps, theme } from 'antd';
import axios from 'axios';
import { BaseUrl } from '../adminPage/BaseUrl';
import PrensentPersonal from './PresentPersonal';
import PresentEducate from './PresentEducate';
import PresentAddition from './PresentAddition';
import { useNavigate } from 'react-router-dom';



const AllComponent = () => {
  
  const [SelectValue, setSelectValue] = useState("")

  const [PresentPersonalData, setPresentPersonalData] = useState({
    section_id: localStorage.getItem("userRole") == 0 ? SelectValue : localStorage.getItem("section_id"),
    jshshr: "",
    foto3x4:"",
    gander:"",
    birthDate:"",
    passport: "",
    fullName: "",
    region: "",
    distirct: "",
    MFY:"",
    street:"",
    bulim: "",
    position: "",
    positionDate: "",
    phoneNumber: "",
    obektFile: "",
    talimData: [{
      talimOlganDavlat: "",
      talimDaraja: "",
      OTMnomi: "",
      mutahasis: "",
      endDate: "",
    }],
    languageData: [{
      language: "",
      degree: "",
      file: ""
    }],
    davlatMukofoti: "",
    idoraviyMukofot: "",
    fandomzodiPhDhudud: "",
  })
  
  console.log(PresentPersonalData);
  const navigate = useNavigate()

  const formData = new FormData();

  for (const key in PresentPersonalData) {
    if (PresentPersonalData.hasOwnProperty(key)) {
      const value = PresentPersonalData[key];
      if (Array.isArray(value)) {
        for (let i = 0; i < value.length; i++) {
          const subObj = value[i];
          for (const subKey in subObj) {
            if (subObj.hasOwnProperty(subKey)) {
              const subValue = subObj[subKey];
              const subKeyWithIndex = `${key}[${i}][${subKey}]`;
              formData.append(subKeyWithIndex, subValue);
            }
          }
        }
      } else {
        formData.append(key, value);
      }
    }
  }

  

  const postAllData = () => {
    if (localStorage.getItem("userRole") == 0) {
      axios.post(BaseUrl + "/api/superAdmin/xodimcv", formData, {
        headers: {
          "Authorization": "Bearer Bearer " + localStorage.getItem("token")
        }
      }).then(res => {
        console.log(res)
        if(res.status == 200){
          navigate("/Bulim_admin/present_employee")
        }
        console.log("salom");
        // message.success(response.data.message)
        if(res.status == 200){
          navigate("/Bulim_admin/present_employee")
        }
      }).catch(err => {
        console.log(err)
        message.error(err.response.data.message)
      })
    }
    else  if (localStorage.getItem("userRole") == 2) {
      axios.post(BaseUrl + "/api/bulim/xodimcv", formData, {
        headers: {
          "Authorization": "Bearer Bearer " + localStorage.getItem("token")
        }
      }).then(res => {
        console.log(res)
        if(res.status == 200){
          navigate("/Bulim_admin/present_employee")
          message.success(res.data.message)
        }
        // console.log("salom");
        // message.success(response.data.message)
        if(res.status == 200){
          navigate("/Bulim_admin/present_employee")
        }
      }).catch(err => {
        console.log(err)
        message.error(err.response.data.message)
      })
    }
  }


  const steps = [
    {
      title: "Shaxsiy ma'lumotlar",
      content: <PrensentPersonal setPresentPersonalData={setPresentPersonalData} PresentPersonalData={PresentPersonalData} SelectValue={SelectValue} setSelectValue={setSelectValue}/>,
    },
    {
      title: "Ta'lim ma'lumotlar",
      content: <PresentEducate setPresentPersonalData={setPresentPersonalData} PresentPersonalData={PresentPersonalData} />,
    },
    {
      title: "Qo'shimcha ma'lumotlar",
      content: <PresentAddition setPresentPersonalData={setPresentPersonalData} PresentPersonalData={PresentPersonalData} />,
    },
  ];

  // () => message.success('Processing complete!')
  const { token } = theme.useToken();
  const [current, setCurrent] = useState(0);
  const next = () => {
    setCurrent(current + 1);
  };
  const prev = () => {
    setCurrent(current - 1);
  };
  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));
  const contentStyle = {
    lineHeight: '260px',
    textAlign: 'center',
    color: token.colorTextTertiary,
    backgroundColor: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: `1px dashed ${token.colorBorder}`,
    marginTop: 16,
  };

  return (
    <div className="conteiner-fluid">
      <div className="row mt-5 mb-3" style={{ display: "flex", justifyContent: "center", padding: "0", margin: "0" }}>
        <div className="col-12 col-sm-12 col-md-10 col-lg-6 col-xl-6 col-xxl-6">
          <Steps current={current} items={items} />
          <div style={{ contentStyle, backgroundColor: "white", padding: "20px", margin: "20px 0" }}>{steps[current].content}</div>
          <div style={{ marginTop: 24, display: "flex", justifyContent: "space-between" }}>
            {current < steps.length - 1 && (
              <Button type="primary" onClick={() => next()}>
                Keyingi
              </Button>
            )}
            {current === steps.length - 1 && (
              <Button type="primary" onClick={postAllData}>
                Done
              </Button>
            )}
            {current > 0 && (
              <Button
                style={{
                  margin: '0 8px',
                }}
                onClick={() => prev()}
              >
                Oldingi
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default AllComponent;