import { LoginOutlined,EditOutlined,PlusOutlined,DownloadOutlined} from '@ant-design/icons'
import React from "react";
import { Breadcrumb, Layout, Menu, theme } from 'antd';
import {Routes, Route, Link, useNavigate} from "react-router-dom"
import Employee from "./employee";
import Kpage from "./Kpage";
import Bpage from "./Bpage";
import Fakultet from "./fakultet";
import MainRahbarHodim from "../RahbarHodinlar/MainRahbarHodim";
import MainOddiyHodim from "../OddiyHodimlar/MainOddiyHodim";
import UserList from "./userList/userList";
const { Header, Content, Footer } = Layout;



const AdminMAin = () =>{

  const navigate = useNavigate()

    const {
        token: { colorBgContainer },
      } = theme.useToken();

      const logOut = () =>{
        localStorage.removeItem("section_id")
        localStorage.removeItem("id")
        localStorage.removeItem("username")
        localStorage.removeItem("email")
        localStorage.removeItem("userRole")
        localStorage.removeItem("token")
        navigate("/")
      }

    return(
        <Layout>
        <Header style={{position: 'sticky',top: 0, zIndex: 1, width: '100%'}}>
          {/* <div style={{float: 'left', width: 120,  background: 'rgba(255, 255, 255, 0.2)', }}/> */}
          <div style={{display:"flex",justifyContent:"space-between"}}>
            <Menu theme="dark"mode="horizontal"defaultSelectedKeys={['1']} style={{height:"64px"}}>
            <div style={{display:"flex",boxSizing:"border-box"}}>
              <img style={{width:"50px", height:"50px",marginTop:"10px",borderRadius:"50%"}} src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/77/Emblem_of_Uzbekistan.svg/250px-Emblem_of_Uzbekistan.svg.png"/>
              <p style={{marginLeft:"10px",fontSize:"20px"}}>Ma'lumotlar bazasi</p>
          </div>
            <Menu.Item style={{marginLeft:"40px"}}  className="menu-main-item" key="1">
                          <Link to="/main/">
                            <div className="in_menu_item">
                                {/* <FormOutlined /> */}
                                    <div className="for_menu_text">Pedagoglar ro'yxati</div>
                            </div>
                          </Link>
            </Menu.Item>
            <Menu.Item  className="menu-main-item" key="2">
                          <Link to="/main/rahbar">
                            <div className="in_menu_item">
                                {/* <FormOutlined /> */}
                                    <div className="for_menu_text">Rahbar xodimlar</div>
                            </div>
                          </Link>
            </Menu.Item>
            <Menu.Item  className="menu-main-item" key="3">
                          <Link to="/main/present">
                            <div className="in_menu_item">
                                {/* <FormOutlined /> */}
                                    <div className="for_menu_text">Oddiy xodimlar</div>
                            </div>
                          </Link>
            </Menu.Item>
            <Menu.Item  className="menu-main-item" key="4">
                          <Link to="/main/bulim">
                          <div className="in_menu_item">
                              {/* <FormOutlined /> */}
                                  <div className="for_menu_text">Bo'limlar</div>
                              </div>
                          </Link>
            </Menu.Item>
            <Menu.Item  className="menu-main-item" key="5">
                          <Link to="/main/kafedra">
                          <div className="in_menu_item">
                              {/* <FormOutlined /> */}
                                  <div className="for_menu_text">Kafedralar</div>
                              </div>
                          </Link>
            </Menu.Item>
            <Menu.Item  className="menu-main-item" key="6">
                          <Link to="/main/fakultet">
                          <div className="in_menu_item">
                              {/* <FormOutlined /> */}
                                  <div className="for_menu_text">Fakultetlar</div>
                              </div>
                          </Link>
            </Menu.Item>
            <Menu.Item  className="menu-main-item" key="7">
                          <Link to="/main/userList">
                          <div className="in_menu_item">
                              {/* <FormOutlined /> */}
                                  <div className="for_menu_text">Foydalanuvchilar</div>
                              </div>
                          </Link>
            </Menu.Item>
            </Menu>
            <div style={{display:"flex",justifyContent:"space-between",width:"230px"}}>
            <div>
                <p style={{color:"white"}}>{localStorage.getItem("username")}</p>
              </div>
              <div onClick={logOut} style={{color:"white",display:"flex",alignItems:"center",cursor:"pointer"}}>
                <p style={{}}>Log Out</p>
                <LoginOutlined style={{fontSize:"20px",marginTop:"-15px",marginLeft:"10px"}}/>
              </div>
            </div>
          </div>
        </Header>
        <Content className="site-layout"style={{padding: '0 50px', }} >
        <Breadcrumb style={{margin: '16px 0',}}> </Breadcrumb>
          <div style={{ padding: 24, height:"87vh",background: colorBgContainer,}}>
           {
            <Routes>
              <Route path="/" element={<Employee/>}/>
              <Route path="/rahbar" element={<MainRahbarHodim/>}/>
              <Route path="/present" element={<MainOddiyHodim/>}/>
              <Route path="/bulim" element={<Bpage/>}/>
              <Route path="/kafedra" element={<Kpage/>}/>
              <Route path="/fakultet" element={<Fakultet/>}/>
              <Route path="/userList" element={<UserList/>}/>
            </Routes>
           }
          </div>
        </Content>
      </Layout>
    )
}

export default AdminMAin;