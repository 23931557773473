import React, { useEffect, useState } from "react";
import { DeleteOutlined,EditOutlined,PlusOutlined,DownloadOutlined} from '@ant-design/icons'
import {Routes, Route, Link} from "react-router-dom"
import { Breadcrumb, Layout, Menu, theme,Button } from 'antd';
import axios from "axios";
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AddUser from "../userPage/AddUser";

import { BaseUrl } from "./BaseUrl";

const Employee = () =>{

    const [employe, setEmployee] = useState([]) 
    const [text, setText] = useState(false)
    const [personcv, setPersoncv] = useState({})
    const [personlanguages, setPersonlanguages] = useState([])
    const [persontalims, setPersontalims] = useState([])

    const [open, setOpen] = useState(false);
    const showDrawer = () => {
      setOpen(true);
    };
    const onClose = () => {
      setOpen(false);
    };

    const getOneCv = (id) =>{
        axios.get(BaseUrl+"/api/superAdmin/personcv/"+id,{
          headers: {
              "Authorization": "Bearer Bearer " + localStorage.getItem("token")
          }
      }).then(res=>{
          console.log(res.data);
          setPersoncv(res.data.personcv)
          setPersonlanguages(res.data.personlanguages)
          setPersontalims(res.data.persontalims)
          // console.log(personcv);
          // setEmployee(res.data)
        }).catch(err=>{
          console.log(err);
        })
      }

    const getAllEmployee = () =>{
        axios.get(BaseUrl + "/api/superAdmin/personcv",{
            headers: {
                "Authorization": "Bearer Bearer " + localStorage.getItem("token")
            }
        }).then(res=>{
            console.log(res.data);
            setEmployee(res.data)
        }).catch(err=>{
            console.log(err);
        })
    }
    // console.log(PersonalData);

    useEffect(()=>{
        getAllEmployee()
    },[])

    const deleteCv = (id) =>{
        axios.delete(BaseUrl+"/api/kafedra/personcv/"+id,{
          headers: {
              "Authorization": "Bearer Bearer " + localStorage.getItem("token")
          }
      }).then(res=>{
        console.log(res.status);
        getAllEmployee()
        setText(res.data.message)
        console.log(id);
      }).catch(err=>{
        console.log(err);
        setText(err.response.data.message)
      })
      }

      
    
    return(
        <div>
             <ToastContainer/>
             <div style={{width:"100%", display:"flex",justifyContent:"space-between",margin:"0 0 10px 0"}}>
             {
                localStorage.getItem("userRole")==0 ? <Link to={BaseUrl+"/personcv/export"}>
                <Button type="primary" icon={<DownloadOutlined style={{fontSize:"18px"}}/>}>
                    excel
                </Button>
                </Link>:""
             }
                <Link to="/addEmployee">
                <Button type="primary" icon={<PlusOutlined />}>
                    Xodim qo'shish
                </Button>
                </Link>
            </div>
        <table class="table table-bordered">
            <thead>
                <tr>
                    <th scope="col">ID</th>
                    <th scope="col">Familiya</th>
                    <th scope="col">Bo'lim nomi</th>
                    <th scope="col">Lavozim</th>
                    <th scope="col">Oxirgi yangilanish</th>
                    <th scope="col">Ma'lumotnoma</th>
                    <th scope="col" style={{textAlign:"center"}}>Amallar</th>
                </tr>
            </thead>
            <tbody>
            {
                    employe && employe.map((item, index)=>(
                        <tr className="align-middle" key={index}>
                            <td scope="col">{item.id}</td>
                            <td  style={{cursor:"pointer"}} scope="col" onClick={()=>getOneCv(item.id)}><p onClick={()=>showDrawer()} className="text-primary m-0"><img style={{width:"60px",height:"60px"}} src={BaseUrl+"/storage/"+item.foto3x4} alt="user"/> {item.fullName}</p></td>
                            <td scope="col">{item.bulim}</td>
                            <td scope="col">{item.position}</td>
                            <td scope="col">{item.updated_at.slice(0,10)}</td>
                            <td scope="col"><a href={BaseUrl+"/storage/"+item.obektFile} target={'_blank'}><button className="btn btn-primary" style={{border:"none",display:"flex",alignItems:"center"}}><DownloadOutlined /> Yuklab olish</button></a></td>
                            <td scope="col">
                            {/* <div className="border" style={{width:"35px", height:"35px", borderRadius:"5px",display:"flex", alignItems:"center", justifyContent:"center", fontSize:"20px",cursor:"pointer"}}><EditOutlined /></div> */}
                            <div className='trash_stayle' style={{margin:"0 auto",width:"35px", height:"35px", borderRadius:"5px",display:"flex", alignItems:"center", justifyContent:"center",backgroundColor:"red",color:"white", fontSize:"20px",marginLeft:"5px",cursor:"pointer"}} onClick={()=>deleteCv(item.id)}><DeleteOutlined /></div>
                            </td>
                     </tr>
                    ))
                }
            </tbody>
        </table>
        <AddUser personcv={personcv} personlanguages={personlanguages} persontalims={persontalims} showDrawer={showDrawer} onClose={onClose} open={open}/>
        </div>
        
    )
}

export default Employee;