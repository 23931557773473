import { useState } from 'react';
import { Button, message, Steps, theme } from 'antd';
// import "./main.css"

import axios from 'axios';
import { BaseUrl } from '../adminPage/BaseUrl';
import { Navigate, useNavigate } from 'react-router-dom';
import RaxbarCareer from './RaxbarCareer';
import RaxbarEducate from './RaxbarEducate';
import RaxbarAddition from './RaxbarAddition';
import RaxbarPersonal from './RaxbarPersonal';



const PostRaxbar = () => {
  
  const [SelectValue, setSelectValue] = useState("")

  const [RaxbarPersonalData, setRaxbarPersonalData] = useState({
    section_id: localStorage.getItem("userRole") == 0 ? SelectValue : localStorage.getItem("section_id"),
    gander:"",
    jshshr: "",
    foto3x4:"",
    passport: "",
    fullName: "",
    birthDate:"",
    region: "",
    distirct: "",
    MFY:"",
    street:"",
    bulim: "",
    position: "",
    positionDate: "",
    phoneNumber: "",
    obektFile: "",
    lavozimgaTayin: "",
    vazirlikLavozim: "",
    pedagok: "",
    rahbar: "",
    boshqa: "",
    rektor: "",
    talimData: [{
      talimOlganDavlat: "",
      talimDaraja: "",
      OTMnomi: "",
      mutahasis: "",
      endDate: "",
    }],
    languageData: [{
      language: "",
      degree: "",
      file: ""
    }],
    davlatMukofoti: "",
    idoraviyMukofot: "",
    fandomzodiPhDhudud: "",
    fandomzodiPhDseriya:"",
    fandomzodiPhDdate:"",
    fandomzodiDschudud: "",
    fandomzodiDscseriya:"",
    fandomzodiDscdate:"",
    fandomzodiPhD: "",
    fandomzodiDsc: "",
    ilmiyUnvon: "",
    Hindex: "",
    googleScholar: ""
  })
  
  console.log(RaxbarPersonalData);

  const navigate = useNavigate()

  const formData = new FormData();

  for (const key in RaxbarPersonalData) {
    if (RaxbarPersonalData.hasOwnProperty(key)) {
      const value = RaxbarPersonalData[key];
      if (Array.isArray(value)) {
        for (let i = 0; i < value.length; i++) {
          const subObj = value[i];
          for (const subKey in subObj) {
            if (subObj.hasOwnProperty(subKey)) {
              const subValue = subObj[subKey];
              const subKeyWithIndex = `${key}[${i}][${subKey}]`;
              formData.append(subKeyWithIndex, subValue);
            }
          }
        }
      } else {
        formData.append(key, value);
      }
    }
  }

  

  const postAllData = () => {
    console.log("Salom");
    if (localStorage.getItem("userRole")==2) {
      axios.post(BaseUrl + "/api/bulim/rahbarcv", formData, {
        headers: {
          "Authorization": "Bearer Bearer " + localStorage.getItem("token")
        }
      }).then(res => {
        console.log(res)
        if (res.status == 200) {
          navigate("/Bulim_admin")
          message.success(res.data.message)
         }
        // message.success(response.data.message)
      }).catch(err => {
        console.log(err)
        message.error(err.response.data.message)
      })
    }
    else if(localStorage.getItem("userRole")==0) {
      axios.post(BaseUrl + "/api/superAdmin/rahbarcv", formData, {
        headers: {
          "Authorization": "Bearer Bearer " + localStorage.getItem("token")
        }
      }).then(res => {
        console.log(res)
        if (res.status == 200) {
          navigate("/main/rahbar")
          message.success(res.data.message)
         }
        // message.success(response.data.message)
      }).catch(err => {
        console.log(err)
        message.error(err.response.data.message)
      })
    }
  }


  const steps = [
    {
      title: "Shaxsiy ma'lumotlar",
      content: <RaxbarPersonal setRaxbarPersonalData={setRaxbarPersonalData} RaxbarPersonalData={RaxbarPersonalData} SelectValue={SelectValue} setSelectValue={setSelectValue}/>,
    },
    {
      title: "Mehnat faoliyati",
      content: <RaxbarCareer setRaxbarPersonalData={setRaxbarPersonalData} RaxbarPersonalData={RaxbarPersonalData}/>,
    },
    {
      title: "Ta'lim ma'lumotlar",
      content: <RaxbarEducate setRaxbarPersonalData={setRaxbarPersonalData} RaxbarPersonalData={RaxbarPersonalData}/>,
    },
    {
      title: "Qo'shimcha ma'lumotlar",
      content: <RaxbarAddition setRaxbarPersonalData={setRaxbarPersonalData} RaxbarPersonalData={RaxbarPersonalData}/>,
    },
  ];

  // () => message.success('Processing complete!')
  const { token } = theme.useToken();
  const [current, setCurrent] = useState(0);
  const next = () => {
    setCurrent(current + 1);
  };
  const prev = () => {
    setCurrent(current - 1);
  };
  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));
  const contentStyle = {
    lineHeight: '260px',
    textAlign: 'center',
    color: token.colorTextTertiary,
    backgroundColor: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: `1px dashed ${token.colorBorder}`,
    marginTop: 16,
  };

  return (
    <div className="conteiner-fluid">
      <div className="row mt-5 mb-3" style={{ display: "flex", justifyContent: "center", padding: "0", margin: "0" }}>
        <div className="col-12 col-sm-12 col-md-10 col-lg-6 col-xl-6 col-xxl-6">
          <Steps current={current} items={items} />
          <div style={{ contentStyle, backgroundColor: "white", padding: "20px", margin: "20px 0" }}>{steps[current].content}</div>
          <div style={{ marginTop: 24, display: "flex", justifyContent: "space-between" }}>
            {current < steps.length - 1 && (
              <Button type="primary" onClick={() => next()}>
                Keyingi
              </Button>
            )}
            {current === steps.length - 1 && (
              <Button type="primary" onClick={postAllData}>
                Done
              </Button>
            )}
            {current > 0 && (
              <Button
                style={{
                  margin: '0 8px',
                }}
                onClick={() => prev()}
              >
                Oldingi
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default PostRaxbar;