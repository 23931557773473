import React from "react";
import { PlusOutlined,DownloadOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Drawer, Form, Input, Row, Select, Space } from 'antd';
import { BaseUrl } from "../adminPage/BaseUrl";
import { Link } from "react-router-dom";
const { Option } = Select;

const DeteilOddiyHodim = (props) =>{

    const {open, showDrawer,onClose,personcv,personlanguages,persontalims} = props

    return(
        <Drawer
        title="Barcha malumotlar"
        width={720}
        onClose={onClose}
        open={open}
        bodyStyle={{
          paddingBottom: 80,
        }}
        // extra={
        //   <Space>
        //     <Button onClick={onClose}>Cancel</Button>
        //     <Button onClick={onClose} type="primary">
        //       Submit
        //     </Button>
        //   </Space>
        // }
      >
        <Form layout="vertical" hideRequiredMark>
          <div style={{display:"flex",alignItems:"center",marginTop:"-10px"}}><h5>Ism familiya :</h5> <p style={{fontSize:"18px", marginTop:"9px",marginLeft:"5px"}}>{personcv.fullName}</p></div>
          <div style={{display:"flex",alignItems:"center",marginTop:"-10px"}}><h5>J.SH.SH.I.R :</h5> <p style={{fontSize:"18px", marginTop:"9px",marginLeft:"5px"}}>{personcv.jshshr}</p></div>
          <div style={{display:"flex",alignItems:"center",marginTop:"-10px"}}><h5>Jinsi :</h5> <p style={{fontSize:"18px", marginTop:"9px",marginLeft:"5px"}}>{personcv.gander}</p></div>
          <div style={{display:"flex",alignItems:"center",marginTop:"-10px"}}><h5>Lavozimi :</h5> <p style={{fontSize:"18px", marginTop:"9px",marginLeft:"5px"}}>{personcv.position}</p></div>
          <div style={{display:"flex",alignItems:"center",marginTop:"-10px"}}><h5>Ish boshlagan sana :</h5> <p style={{fontSize:"18px", marginTop:"9px",marginLeft:"5px"}}>{personcv.positionDate}</p></div>
          <div style={{display:"flex",alignItems:"center",marginTop:"-10px"}}><h5>Telefon raqam :</h5> <p style={{fontSize:"18px", marginTop:"9px",marginLeft:"5px"}}>{personcv.phoneNumber}</p></div>
          <div style={{display:"flex",alignItems:"center",marginTop:"-10px"}}><h5>Tugilgan sana :</h5> <p style={{fontSize:"18px", marginTop:"9px",marginLeft:"5px"}}>{personcv.birthDate}</p></div>
          <div style={{display:"flex",alignItems:"center",marginTop:"-10px"}}><h5>Tugilgan viloyati :</h5> <p style={{fontSize:"18px", marginTop:"9px",marginLeft:"5px"}}>{personcv.region}</p></div>
          <div style={{display:"flex",alignItems:"center",marginTop:"-10px"}}><h5>Tugilgan tumani :</h5> <p style={{fontSize:"18px", marginTop:"9px",marginLeft:"5px"}}>{personcv.distirct}</p></div>
          <div style={{display:"flex",alignItems:"center",marginTop:"-10px"}}><h5>MFY :</h5> <p style={{fontSize:"18px", marginTop:"9px",marginLeft:"5px"}}>{personcv.MFY}</p></div>
          <div style={{display:"flex",alignItems:"center",marginTop:"-10px"}}><h5>Ko'cha :</h5> <p style={{fontSize:"18px", marginTop:"9px",marginLeft:"5px"}}>{personcv.street}</p></div>
          <div style={{display:"flex",alignItems:"center",marginTop:"-10px"}}><h5>Davlat mukofoi :</h5> <p style={{fontSize:"18px", marginTop:"9px",marginLeft:"5px"}}>{personcv.davlatMukofoti}</p></div>
          <div style={{display:"flex",alignItems:"center",marginTop:"-10px"}}><h5>Idoraviy mukofoti :</h5> <p style={{fontSize:"18px", marginTop:"9px",marginLeft:"5px"}}>{personcv.idoraviyMukofot}</p></div>
          <div style={{display:"flex",alignItems:"center",marginTop:"-10px"}}><h5>Pasport berilgan sana :</h5> <p style={{fontSize:"18px", marginTop:"9px",marginLeft:"5px"}}>{personcv.passport}</p></div>
          <h4>Xorijiy til bilish darajasi</h4>
          <hr/>
          {
            personlanguages.map((item, index)=>(
              <div style={{margin:"0px 0 0 0"}}>
                <div style={{width:"100%", display:"flex"}}>
                  <h5 style={{fontSize:"18px"}}>{item.language}</h5>   <p style={{fontSize:"18px",marginLeft:"20px",marginTop:"-5px"}}>{item.degree}</p>
                </div>
                <Link to={BaseUrl+"/storage/"+item.file} target='_blank' style={{border:"none",display:"flex",alignItems:"center", width:'230px'}} className="btn btn-primary">
                <DownloadOutlined style={{margin:"0 5px 0 0"}}/>Sertifikatni yuklab olish
                  {/* <button className="btn btn-primary" > </button> */}
                </Link>
                <div style={{width:"50%"}}><hr/></div>
              </div>
            ))
          }

            <h4>Tamomlagan oliy ta'lim muassasi</h4>
            <hr/>
                    {
                      persontalims.map((item, index)=>(
                        <div style={{margin:"0px 0 0 0"}}>
                          <div style={{width:"100%", display:"flex"}}>
                            <h5 style={{fontSize:"18px"}}>Ta'lim olgan muassasa</h5>   <p style={{fontSize:"18px",marginLeft:"20px",marginTop:"-5px"}}>{item.OTMnomi}</p>
                          </div>
                          <div style={{width:"100%", display:"flex"}}>
                            <h5 style={{fontSize:"18px"}}>Ta'lim olgan davlat</h5>   <p style={{fontSize:"18px",marginLeft:"20px",marginTop:"-5px"}}>{item.talimOlganDavlat}</p>
                          </div>
                          <div style={{width:"100%", display:"flex"}}>
                            <h5 style={{fontSize:"18px"}}>Mutahasisligi</h5>   <p style={{fontSize:"18px",marginLeft:"20px",marginTop:"-5px"}}>{item.mutahasis}</p>
                          </div>
                          <div style={{width:"100%", display:"flex"}}>
                            <h5 style={{fontSize:"18px"}}>Ta'lim daraja</h5>   <p style={{fontSize:"18px",marginLeft:"20px",marginTop:"-5px"}}>{item.talimDaraja}</p>
                          </div>
                          <div style={{width:"100%", display:"flex"}}>
                            <h5 style={{fontSize:"18px"}}>Tamomlagan yil</h5>   <p style={{fontSize:"18px",marginLeft:"20px",marginTop:"-5px"}}>{item.endDate}</p>
                          </div>
                          <div style={{width:"50%"}}><hr/></div>
                        </div>
                      ))
                    }
        </Form>
      </Drawer>
    )
}

export default DeteilOddiyHodim;